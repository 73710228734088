import React, { Component } from "react"

const SENSITIVITY = 0.1

class USBController extends Component {
    constructor(props) {
        super(props)

        this.clawButton = false
        this.armButton = false
        this.direction = [0, 0]
        this.arm = [0]

        this.state = {
            gamepadIndex: -1
        }
    }

    shouldComponentUpdate() {
        return false
    }

    componentDidMount() {
        window.addEventListener("gamepadconnected", (e)=>{
            this.setState({ gamepadIndex: e.gamepad.index })
            this._startUSBControllerLoop()

            console.log("Gamepad connected at index %d: %s. %d buttons, %d axes.",
                e.gamepad.index, e.gamepad.id,
                e.gamepad.buttons.length, e.gamepad.axes.length);
        })
    }

    _hasChanged = (newVal, pastVal)=>{
        return Math.abs(newVal - pastVal) > SENSITIVITY
    }

    _startUSBControllerLoop = ()=>{

        if (this.props.disabled) {
            return false
        }

        const GI = this.state.gamepadIndex

        if (GI > -1){

            let armHasChanged = false
            let directionHasChanged = false

            const directionAxes = [parseFloat(navigator.getGamepads()[GI].axes[0].toFixed(2)), parseFloat(navigator.getGamepads()[GI].axes[1].toFixed(2))]
            const armAxes = [parseFloat(navigator.getGamepads()[GI].axes[2].toFixed(2))]
            const clawButton = navigator.getGamepads()[GI].buttons[7].pressed
            const armButton = navigator.getGamepads()[GI].buttons[6].pressed

            if (clawButton !== this.clawButton || armButton !== this.armButton || this._hasChanged(armAxes[0], this.arm[0])) {
                armHasChanged = true
                this.clawButton = clawButton
                this.armButton = armButton
                this.arm = armAxes
            }

            if (this._hasChanged(directionAxes[0], this.direction[0]) || this._hasChanged(directionAxes[1], this.direction[1])) {
                directionHasChanged = true
                this.direction = directionAxes
            }

            let send = null
            if (armHasChanged){
                if(!send) send = {}
                // send['arm'] = [armAxes[0]*-1, 0, clawButton ? -1:1]
                send['arm'] = [armButton ? 1 : -1, 0, clawButton ? -1 : 1]
            }
            if (directionHasChanged){
                if (!send) send = {}
                send['direction'] = [directionAxes[0], directionAxes[1]*-1]
            }

            if(send && this.props.socket){
                this.props.socket.send(JSON.stringify(send))
            }

        }

        // setTimeout(()=>{
        //     this._startUSBControllerLoop()
        // }, 500)

        requestAnimationFrame(this._startUSBControllerLoop)
        //const start =
    }

    _sendToSocket = obj => {
        if (
            Math.abs(obj.grabStrength - this.lastSentGrabStrength) >
                SENSITIVITY ||
            (obj.grabStrength === 1 && this.lastSentGrabStrength !== 1) ||
            (obj.grabStrength === -1 && this.lastSentGrabStrength !== -1)
        ) {
            console.log({ arm: [-1, 0, obj.grabStrength] })

            if (this.props.socket) {
                this.props.socket.send(
                    JSON.stringify({ arm: [-1, 0, obj.grabStrength] }),
                )
            }

            this.initialHandTwist = null

            this.lastSentGrabStrength = obj.grabStrength
        }
    }

    _remap = value => {
        return ((value - 0) * (1 - -1)) / (1 - 0) + -1
    }

    render() {
        return (
            <div>
                <p>USB CONTROLLER COMPONENT INCLUDED</p>
            </div>
        )
    }
}

export default USBController