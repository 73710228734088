import React, { Component } from 'react';
import { JContext, JConsumer } from "../../JContext";
import Nipple from './nipple-joystick.js';

const joyOptions = {
    mode: 'dynamic',
    color: '#7FB3D5',
    size: 200,
    multitouch: true,
    maxNumberOfNipples: 11
}

const containerStyle = {
    position: 'relative',
    height: '350px',
    width: '350px',
    background: 'rgba(240, 248, 255, 0.8)'
}

class JoystickInput extends Component {
    constructor(props) {
        super(props);
        this.managerListener = this.managerListener.bind(this);

        this.state = {
            stickIdentifier: null,
            stickPressure: null,
            stickDistance: null,
            stickDirectionAngle: null,
            stickAngleDegree: null
        }
    }

    _getSafely(fn, defaultVal) {
        try {
            return fn();
        } catch (e) {
            return defaultVal;
        }
    }

    managerListener(manager) {
        manager.on('start', (e, stick) => {
            console.log(`I started! #${stick.identifier}`)
            // if (stick.identifier != "to the first finger instance") {
            //     // Let it steer the arm directoins, and claw (by pressure)
            // }
        })
        manager.on('move', (e, stick) => {
            //console.log(`I moved! #${ stick.identifier }`)
            //console.log('stick', stick);
            //console.log('e', e);
            this.setState({
                stickIdentifier: this._getSafely(() => stick.identifier, undefined),
                stickPressure: this._getSafely(() => (Math.round(stick.pressure) * 100) / 100, undefined),
                stickDistance: this._getSafely(() => Math.round(stick.distance), undefined),
                stickDirectionAngle: this._getSafely(() => stick.direction.angle, undefined),
                stickAngleDegree: this._getSafely(() => Math.round(stick.angle.degree), undefined),
            })

            this.context._onDirectionalInput({
                directionX: Math.round( ( stick.instance.frontPosition.x / 50) * 100)/200,
                directionY: Math.round( (-stick.instance.frontPosition.y / 50) * 100)/200
            })

            this.context._sendDirection(true, true) // send first argument boolean true to use CHANGE_SENSITIVITY for checking if directions already are sent or not compared to the constant value. the second argument constitutes if lastVal should be set after comparing newVal to lastVal.
        })
        manager.on('end', (e, stick) => {
            //console.log(`I ended! #${stick.identifier}`)
            this.context._onKeyPressSpace()
        })
    }

    render() {
        //const { classes } = this.props;
        return (
            <JConsumer>
                { (context) =>
                    <div className="Nipple">
                        <Nipple options={joyOptions} containerStyle={containerStyle} managerListener={this.managerListener} />
                        <div className="Nipple-debug">
                            <ul>
                                <li className="identifier">#<span className='data'>{this.state.stickIdentifier}</span></li>
                                <li className="directionX">X : <span className='data'>{context.directionX}</span></li>
                                <li className="directionY">Y : <span className='data'>{context.directionY}</span></li>
                                <li className="pressure">pressure : <span className='data'>{this.state.stickPressure}</span></li>
                                <li className="distance">distance : <span className='data'>{this.state.stickDistance}</span></li>
                                <li className="angle">angle : <span className='data'>{this.state.stickDirectionAngle}</span></li>
                                <li className="degree">degree : <span className='data'>{this.state.stickAngleDegree}</span></li>
                            </ul>
                            {/* <div className="dump"></div> */}
                        </div>
                    </div>
                }
            </JConsumer>
        )
    }
}

JoystickInput.contextType = JContext; // enables this.context to be used outside of render()

export default JoystickInput;