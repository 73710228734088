import React, { Component } from 'react';
import { JConsumer } from "../../JContext";

class SliderInput extends Component {
    render() {
        return (
            <JConsumer>
                { (context) =>
                <React.Fragment>
                    <div className="form-group form-group--slider">
                        <label  htmlFor="App-slider--armX">Arm X</label>
                        <input  className="App-slider App-slider--armX"
                                name="App-slider--armX"
                                list="armX-dl"
                                type="range"
                                min="-1"
                                max="1"
                                step="0.01"
                                value={context.armX}
                                onChange={(e) => context._onSliderChangeArmX(e)}
                                onMouseUp={context._sendDirection}
                                onTouchEnd={context._sendDirection}
                                />
                        <output htmlFor="App-slider--armX">
                            {context.armX}
                        </output>
                        <datalist id="armX-dl">
                            <option value="-1" label="-1"></option>
                            <option value="0" label="0"></option>
                            <option value="1" label="1"></option>
                        </datalist>
                    </div>
                    <div className="form-group form-group--slider">
                        <label  htmlFor="App-slider--armY">Arm Y</label>
                        <input  className="App-slider App-slider--armY"
                                name="App-slider--armY"
                                id="App-slider--armY"
                                list="armY-dl"
                                type="range"
                                min="-1"
                                max="1"
                                step="0.01"
                                value={context.armY}
                                onChange={(e) => context._onSliderChangeArmY(e)}
                                onMouseUp={context._sendDirection}
                                onTouchEnd={context._sendDirection}
                                />
                        <output htmlFor="App-slider--armY">
                            {context.armY}
                        </output>
                        <datalist id="armY-dl">
                            <option value="-1" label="-1"></option>
                            <option value="0" label="0"></option>
                            <option value="1" label="1"></option>
                        </datalist>
                    </div>
                    <div className="form-group form-group--slider">
                        <label htmlFor="App-slider--clawDirection">Claw (Closed ↔︎ Open)</label>
                        <input  className="App-slider App-slider--clawDirection"
                                name="App-slider--clawDirection"
                                list="clawDirection-dl"
                                type="range"
                                min="-1"
                                max="1"
                                step="0.01"
                                value={context.clawDirection}
                                onChange={(e) => context._onSliderChangeClawDirection(e)}
                                onMouseUp={context._sendDirection}
                                onTouchEnd={context._sendDirection}
                                />
                        <output htmlFor="App-slider--clawDirection">
                            {context.clawDirection}
                        </output>
                        <datalist id="clawDirection-dl">
                            <option value="-1" label="-1"></option>
                            <option value="0" label="0"></option>
                            <option value="1" label="1"></option>
                        </datalist>
                    </div>
                </React.Fragment>
                }
            </JConsumer>
        )
    }
}

export default SliderInput