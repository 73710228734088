import React, { Component } from 'react';
import { JConsumer } from "../../JContext";

class KeyboardInput extends Component {

    render() {
        return (
            <JConsumer>
                { (context) =>
                    <div className="form-group">
                    <button className="App-key App-key--space"
                            type="button"
                            onClick={context._onKeyPressSpace}>
                            ■ Stop
                    </button>
                    <button className="App-key App-key--up"
                            type="button"
                            onClick={context._onKeyPressUp}>
                            ↑
                    </button>
                    <button className="App-key App-key--down"
                            type="button"
                            onClick={context._onKeyPressDown}>
                            ↓
                    </button>
                    <button className="App-key App-key--left"
                            type="button"
                            onClick={context._onKeyPressLeft}>
                            ←
                    </button>
                    <button className="App-key App-key--right"
                            type="button"
                            onClick={context._onKeyPressRight}>
                            →
                    </button>
                    </div>
                }
            </JConsumer>
        )
    }
}

export default KeyboardInput