import React, { Component } from 'react';
import { JProvider } from "./JContext";
import logo from './assets/logo/jbot-logo.svg';
import './App.css';
import { JoystickInput } from './components/JoystickInput'
import { KeyboardInput } from './components/KeyboardInput'
import { SliderInput } from './components/SliderInput'
import { SpeakInput } from './components/SpeakInput'
import { LeapMotionTracker } from './components/LeapMotionTracker'
import { USBController } from './components/USBController'

const confetti = require('canvas-confetti')

//const useTestServer = false
const wsUriTest = "ws://echo.websocket.org/"
const vfUriTest = "https://placebear.com/g/320/240"

const wsIP = "213.244.242.177"
const wsPort = "9002"
const wsUri = `ws://${wsIP}:${wsPort}`
const wsProtocol = ["jbot-protocol"]
const vfPort = "9003"
const vfPath = "/stream/video.mjpeg"
const vfUri = `http://${wsIP}:${vfPort}${vfPath}`
const CHANGE_SENSITIVITY = 0.1

class App extends Component {

  constructor(props){
    super(props)

    // Relative output [signed float]. (-1.00) — (0.00) — (1.00)
    // directionX, directionY, armY, armX, clawDirection

    this.state = {
      useTestServer: false,
      wsConnected: false,
      wsIP: wsIP,
      wsUri: wsUri,
      vfUri: vfUri,
      videoFeedConnected: true,
      leapMotionDisabled: false,
      usbControllerDisabled: false,
      IPContainer: false,
      noticeBannerActive: false,
      showVideoContainer: true,
      showKeysContainer: false,
      showPadContainer: false,
      showSpeakContainer: true,
      showMessageFeedContainer: false,
      directionX: 0,
      directionY: 0,
      armX: -1,
      armY: 0,
      clawDirection: 1,
      lastDirectionX: 0,
      lastDirectionY: 0,
      lastArmX: -1,
      lastArmY: 0,
      lastClawDirection: 1,
      speak: '',
      message: '',
      notice: '',
      logFeed: []
    }

    /*
    * The following this._ functions inside the constructor() are used globally by child components
    */
    this._sendDirection = (useChangeSensitivity = false, setLastValAfterCheck = false) => {
      if(setLastValAfterCheck !== true){
        this.setState({
          lastDirectionX: this.state.directionX,
          lastDirectionY: this.state.directionY,
          lastArmX: this.state.armX,
          lastArmY: this.state.armY,
          lastClawDirection: this.state.clawDirection,
        })
      }

      let commandObject = {}
      let shouldSend = false

      // Check if direction- or arm-states already are sent or not
      if
      (
        this.state.armX !== this.state.lastArmX ||
        this.state.armY !== this.state.lastArmY ||
        this.state.clawDirection !== this.state.lastClawDirection
      ) {
        console.log(`Sending arm:         [${this.state.armX}, ${this.state.armY}, ${this.state.clawDirection}]`)
        commandObject = {
          arm: [Number(this.state.armX), Number(this.state.armY), Number(this.state.clawDirection)]
        }
        shouldSend = true
      }

      if
      (
        (this.state.directionX !== this.state.lastDirectionX && useChangeSensitivity === false) ||
        (this.state.directionY !== this.state.lastDirectionY && useChangeSensitivity === false) ||
        (this._hasChanged(this.state.directionX, this.state.lastDirectionX) && useChangeSensitivity === true) ||
        (this._hasChanged(this.state.directionY, this.state.lastDirectionY) && useChangeSensitivity === true)
      ) {
        console.log(`Sending direction:   [${this.state.directionX}, ${this.state.directionY}]`)
        commandObject = {
          direction: [Number(this.state.directionX), Number(this.state.directionY)],
        }
        shouldSend = true
      }

      if(setLastValAfterCheck === true) {
        this.setState({
          lastDirectionX: this.state.directionX,
          lastDirectionY: this.state.directionY,
          lastArmX: this.state.armX,
          lastArmY: this.state.armY,
          lastClawDirection: this.state.clawDirection,
        })
      }

      if (shouldSend) {
        this._sendCommandToWS(commandObject)
      }
    }

    // * Directional and keyboard functions
    this._onKeyPressed = (pushedState = false, pressedKey) => {
      //pressedKey.preventDefault() // Prevent default key thingamajiggy if not in messaging textarea
      pushedState ? console.log('Pressed key', pressedKey) : console.log('Released key', pressedKey)

      // Enter key and §
      var allowedTextareaKeys = [13, 192];

      // Skip the rest of this function if the key is not in the array of allowed keys,
      // to be able to use the keyboard as usual when writing
      if (document.activeElement.classList.contains('App-input')) {
        return // early
      }
      else if (document.activeElement.classList.contains('App-jbot-speak-textarea') && allowedTextareaKeys.indexOf(pressedKey.keyCode) === -1)
      {
        this.setState({
          speak: document.getElementsByClassName('App-jbot-speak-textarea')[0].value
        })

        return // early
      }

      switch (pressedKey.keyCode) {
        case 32:// space key pressed
          pressedKey.preventDefault()
          if (pushedState) {
            this._onKeyPressSpace()
          }
          break;
        case 37:// left key pressed
          pressedKey.preventDefault()
          this.setState({
            directionX: pushedState ? -1 : 0
          }, this._sendDirection)
          break;
        case 38:// up key pressed
          pressedKey.preventDefault()
          this.setState({
            directionY: pushedState ? 1 : 0
          }, this._sendDirection)
          break;
        case 39:// right key pressed
          pressedKey.preventDefault()
          this.setState({
            directionX: pushedState ? 1 : 0
          }, this._sendDirection)
          break;
        case 40:// down key pressed
          pressedKey.preventDefault()
          this.setState({
            directionY: pushedState ? -1 : 0
          }, this._sendDirection)
          break;
        case 87:// W key pressed - Arm Y up
          pressedKey.preventDefault()
          if (-1 <= this.state.armY && this.state.armY < 1) {
            this.setState({
              armY: pushedState ? ((+(this.state.armY).toFixed(1)) + 0.1) : this.state.armY
            }, this._sendDirection)
          }
          break;
        case 83:// S key pressed - Arm Y down
          pressedKey.preventDefault()
          if (-1 < this.state.armY && this.state.armY <= 1) {
            this.setState({
              armY: pushedState ? ((+(this.state.armY).toFixed(1)) - 0.1) : this.state.armY
            }, this._sendDirection)
          }
          break;
        case 65:// A key pressed - Arm X left
          pressedKey.preventDefault()
          if (-1 < this.state.armX && this.state.armX <= 1) {
            this.setState({
              armX: pushedState ? ((+(this.state.armX).toFixed(1)) - 0.1) : this.state.armX
            }, this._sendDirection)
          }
          break;
        case 68:// D key pressed - Arm X right
          pressedKey.preventDefault()
          if (-1 <= this.state.armX && this.state.armX < 1) {
            this.setState({
              armX: pushedState ? ((+(this.state.armX).toFixed(1)) + 0.1) : this.state.armX
            }, this._sendDirection)
          }
          break;
        case 69:// E key pressed - Claw close
          pressedKey.preventDefault()
          this.setState({
            clawDirection: pushedState ? -1 : 1
          }, this._sendDirection)
          break;
        case 13:// enter key pressed
          pressedKey.preventDefault()
          !pushedState && this._onSpeakSubmit()
          break;
        case 192:// § key pressed
          pressedKey.preventDefault()
          //document.activeElement.innerHTML = '' // needs global state handling
          this._resetSpeak()
          break;
        default:
          break;
      }
    }

    this._onKeyPressUp = () => {
      this.setState({
        directionY: 1
      }, this._sendDirection)
    }

    this._onKeyPressDown = () => {
      this.setState({
        directionY: -1
      }, this._sendDirection)
    }

    this._onKeyPressLeft = () => {
      this.setState({
        directionX: -1,
      }, this._sendDirection)
    }

    this._onKeyPressRight = () => {
      this.setState({
        directionX: 1,
      }, this._sendDirection)
    }

    this._onKeyPressSpace = () => {
      this.setState({
        directionX: 0,
        directionY: 0
      }, this._sendDirection)
    }

    this._onDirectionalInput = (e) => {
      this.setState({
        directionX: e.directionX,
        directionY: e.directionY
      })
      // Doesn't send directions, send in parent function instead
    }

    // * Arm functions
    this._onSliderChangeArmX = (e) => {
      this.setState({
        armX: Number(e.target.value)
      })
    }

    this._onSliderChangeArmY = (e) => {
      this.setState({
        armY: Number(e.target.value)
      })
    }

    this._onSliderChangeClawDirection = (e) => {
      this.setState({
        clawDirection: Number(e.target.value)
      })
    }

    // * Speak (speech) functions
    this._onSpeakChange = (e) => {
      this.setState({
        speak: e.target.value
      })
    }

    this._resetSpeak = () => {
      this.setState({
        speak: ''
      })

      //document.getElementsByClassName('App-jbot-speak-textarea')[0].value = ''
    }

    this._onSpeakSubmit = (e) => {
      if (e !== undefined) {
        e.preventDefault()
      }

      //this._onFeedLog(this.state.speak)

      let commandObject = {
        speak: this.state.speak
      }

      this._sendCommandToWS(commandObject)
      //this._resetSpeak()

      // Shoot confetti whenever speech is submitted
      confetti({
        startVelocity: 30,
        spread: 360,
        ticks: 30,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2
        }
        //colors: ['#7FB3D5', '#f0f8ff', '#2D2F32']
      });
    }

    // * IP functions
    this._onIPChange = (e) => {
      this.setState({
        wsIP: e.target.value
      })
    }

    this._onIPSave = (e) => {
      if (e !== undefined) {
        e.preventDefault()
      }
      if (this.state.wsIP === '') {
        this.setState({
          wsIP: wsIP
        })
        localStorage.setItem("jb-wsIP", wsIP)
        this._showThisNotice(`Saved the default IP: ${wsIP}`)
        this._prepareWSConnection()
      }else{
        let regexIPURL = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/

        if(regexIPURL.test(this.state.wsIP)) {
          localStorage.setItem("jb-wsIP", this.state.wsIP)
          this._onFeedLog({
            data: this.state.wsIP,
            timeStamp: Date.now() || e.timeStamp
          })
          this._showThisNotice("", true)
          // Shoot confetti whenever IP is saved
          confetti({
            startVelocity: 30,
            spread: 360,
            ticks: 30,
            origin: {
              x: Math.random(),
              y: Math.random() - 0.2
            },
            colors: ['#7FB3D5', '#f0f8ff', '#2D2F32']
          });

          this._prepareWSConnection()
        }else{
          this._showThisNotice("Didn't save IP or URL because of a syntax error.")
        }
      }
    }
  }

  componentDidMount(){
    this._prepareWSConnection()

    window.addEventListener("keydown", this._onKeyPressed.bind(this, true), false)
    window.addEventListener("keyup", this._onKeyPressed.bind(this, false), false)

    // var _logger = () => {
    //   this._logMessage(arguments);
    //   console.log.call(this, arguments)
    // }
  }

  // * Helper functions
  _hasChanged = (newVal, lastVal) => {
    return Math.abs(newVal - lastVal) > CHANGE_SENSITIVITY
  }

  _prepareWSConnection = (prefix = 'jb-') => {
    if (localStorage.getItem(`${prefix}wsIP`) !== null) {
      let thewsIP = localStorage.getItem(`${prefix}wsIP`);
      this.setState({
        wsIP: thewsIP,
        wsUri: `ws://${thewsIP}:${wsPort}`,
        vfUri: `http://${thewsIP}:${vfPort}${vfPath}`,
      }, this._openWSConnection)
      this._onFeedLog({
        data: thewsIP,
        timeStamp: Date.now()
      })
    }else{
      this._openWSConnection()//send argument true to use test-server
    }
  }

  // * Communication functions
  _sendCommandToWS = (object)=>{
    if (this.state.wsConnected) {
      this.websocket.send(JSON.stringify(object))
      this._onFeedLog(object, true)
    }else{
      console.error('Jerror: Websocket not conneted. Tried to send following object: ', object)
    }
  }

  _openVFConnection = () => {
    this.setState({
      videoFeedConnected: true//(!this.state.videoFeedConnected ? true : false)
    })
  }

  _openWSConnection = () => {
    let websocketLet

    if (this.state.useTestServer) {
      websocketLet = new WebSocket(wsUriTest)
    }else{
      websocketLet = new WebSocket(this.state.wsUri, wsProtocol)
    }

    const websocket = websocketLet

    websocket.onopen = this._onOpen
    websocket.onclose = this._onClose
    websocket.onmessage = this._onMessage
    websocket.onerror = this._onError

    this.websocket = websocket
  }

  _onOpen = (event) => {
    console.log(event)
    this.setState({
      wsConnected: true
    })

    // Shoot confetti on a successful connection
    confetti({
      particleCount: 100,
      startVelocity: 30,
      angle: 315,
      spread: 135,
      ticks: 70,
      origin: {
        x: .015,
        y: .015
      },
      colors: ['#74E08B']
    });
  }

  _onClose = (event) => {
    console.log(event)
    this.setState({
      wsConnected: false
    })

    this._prepareWSConnection()
  }

  _onMessage = (event) => {
    let receivedMessage = JSON.parse(event.data)
    //console.log(event)
    //console.log('receivedMessage', receivedMessage);

    if (receivedMessage.status === 401) {
      this._showThisNotice(receivedMessage.message)
    }else{
      this.setState({
        noticeBannerActive: false
      })
    }

    this.setState({
      message: `☟ ${this.state.message} ${receivedMessage} : ${Math.floor(event.timeStamp)}`
    })

    this._onFeedLog(event)
  }

  _onError = (event) => {
    console.log(event)
  }

  _restartConnection = () => {
    /*
    this.websocket.readyState ===

    0 (CONNECTING) The connection is not yet open.
    1 (OPEN) The connection is open and ready to communicate.
    2 (CLOSING) The connection is in the process of closing.
    3 (CLOSED) The connection is closed or couldn't be opened.

    if (this.state.wsConnected === false&& this.websocket.readyState <= 1) { }
    */
    console.log('Restarting WS connection...');
    this.websocket.close(1000,'Service Restart: 1012')
  }

  // * Status bar state handling
  _toggleuseTestServer = () => {
    this.setState({
      useTestServer: (!this.state.useTestServer ? true : false)
    }, this._restartConnection)
  }

  _togglevideoFeedConnected = () => {
    if (!this.state.videoFeedConnected) {
      this.setState({
        showVideoContainer: !this.state.videoFeedConnected
      })
    }else{
      confetti({
        particleCount: 100,
        startVelocity: 20,
        angle: 270,
        spread: 360,
        ticks: 70,
        origin: {
          x: 0.5,
          y: 0.05,
        },
        colors: ['#900000'],
      })
    }
    this.setState({
      videoFeedConnected: (!this.state.videoFeedConnected ? true : false)
    })
  }

  _toggleleapMotionDisabled = () => {
    this.setState({
      leapMotionDisabled: (!this.state.leapMotionDisabled ? true : false)
    })
  }

  _toggleusbControllerDisabled = () => {
    this.setState({
      usbControllerDisabled: (!this.state.usbControllerDisabled ? true : false)
    })
  }

  _toggleIPContainer = () => {
    this.setState({
      IPContainer: (!this.state.IPContainer ? true : false)
    })
  }

  _togglenoticeBannerActive = () => {
    this.setState({
      noticeBannerActive: (!this.state.noticeBannerActive ? true : false)
    })
  }

  // * UI state handling
  _toggleVideoContainer = () => {
    this.setState({
      showVideoContainer: (!this.state.showVideoContainer ? true : false)
    })
  }

  _toggleKeysContainer = () => {
    this.setState({
      showKeysContainer: (!this.state.showKeysContainer ? true : false)
    })
  }

  _togglePadContainer = () => {
    this.setState({
      showPadContainer: (!this.state.showPadContainer ? true : false)
    })
  }

  _toggleSpeakContainer = () => {
    this.setState({
      showSpeakContainer: (!this.state.showSpeakContainer ? true : false)
    })
  }

  _toggleMessageFeedContainer = () => {
    this.setState({
      showMessageFeedContainer: (!this.state.showMessageFeedContainer ? true : false)
    })
  }

  // * Logging functions
  // _logMessage = (message) => {

  // }

  // * Feed, Notice & Message functions
  _showThisNotice = (message, dismiss = false) => {
    if (dismiss) {
      this.setState({
        noticeBannerActive: false,
      })
      //this._togglenoticeBannerActive()
    } else {
      this.setState({
        noticeBannerActive: true,
        notice: message
      })

      // Let it snow for 1 second whenever the notice banner is called
      let end = Date.now() + (1 * 1000);
      (function frame() {
        confetti({
          particleCount: 1,
          startVelocity: 0,
          ticks: 300,
          origin: {
            x: Math.random(),
            y: 0.09
          },
          colors: ['#ffffff']
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      }());
    }
  }

  _onFeedEmpty = () => {
    this.setState({
      logFeed: []
    })
  }

  _onFeedLog = (logMessage, wasSent = false) => {
    if (wasSent){
      this.state.logFeed.unshift('︎︎︎☝︎ ' + JSON.stringify(logMessage) + ' : ----' )//Math.round( Date.now() / 1000000000) )
    }else{
      this.state.logFeed.unshift('☟ ' + logMessage.data + ' : ' + Math.floor(logMessage.timeStamp) )
    }
    this.setState({
      logFeed: this.state.logFeed
    })
  }

  render() {
    return (
      <div className="App">
        <header className={`App-header ${this.state.videoFeedConnected ? 'video--active' : ''}`}>
          <div className={`App-videoContainer ${this.state.videoFeedConnected ? 'connected' : 'disconnected'} ${this.state.showVideoContainer ? 'show' : 'hide'}`}>
            <img src={this.state.useTestServer ? vfUriTest : this.state.vfUri} className={`App-videoFeed ${this.state.useTestServer ? 'test--active' : ''}`} alt="Videofeed" draggable="false"></img>
          </div>

          { !this.state.videoFeedConnected && <div className="App-headerTitle"><img src={logo} className="App-logo" alt="logo" /><h1 className="App-title">Jbot Remote-Control</h1></div> }

          <div className="App-statusBar">
            <div className="App-statusBarToggles">
              <div className={`App-connectionStatus ${this.state.wsConnected ? 'enabled' : 'disabled'}`}
                  onClick={this._restartConnection}
                  title="Websocket connection status — click to reconnect"><span>WS</span></div>
              <div className={`App-useTestServerState ${this.state.useTestServer ? 'enabled' : 'disabled'}`}
                  onClick={this._toggleuseTestServer}
                  title="Use test-server status"><span>Test</span></div>
              <div className={`App-videoFeedState ${this.state.videoFeedConnected ? 'enabled' : 'disabled'}`}
                  onClick={this._togglevideoFeedConnected}
                  title="Video feed connection status"><span>Video</span></div>
              <div className={`App-leapMotionState ${!this.state.leapMotionDisabled ? 'enabled' : 'disabled'}`}
                  onClick={this._toggleleapMotionDisabled}
                  title="Leap Motion state"><span>Leap</span></div>
              <div className={`App-usbControllerState ${!this.state.usbControllerDisabled ? 'enabled' : 'disabled'}`}
                  onClick={this._toggleusbControllerDisabled}
                  title="USB controller state"><span>USB</span></div>
              <div className={`App-IPContainerState ${!this.state.IPContainer ? 'open' : 'closed'}`}
                  onClick={this._toggleIPContainer}
                title="Toggle showing IP-address field"><span>IP {this.state.IPContainer ? '☝︎' : '☟'}</span></div>
            </div>
            <div className="App-statusBarOptions">
              <div className={`App-IPContainer ${this.state.IPContainer ? 'open' : 'closed'}`}>
                <input className="App-input App-ip-input"
                  placeholder="IP-address to jbot"
                  name="jb-wsIP"
                  value={this.state.wsIP}
                  onChange={(e) => { this._onIPChange(e) }}
                  onKeyPress={(e) => { return (e.key === 'Enter' ? this._onIPSave() : '')}}>
                </input>
                <button className="App-btn dark"
                  type="button"
                  onClick={this._onIPSave}>
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className={`App-noticeBanner ${this.state.noticeBannerActive ? 'enabled' : 'disabled'} ${this.state.IPContainer ? 'statusBar-open' : 'statusBar-closed'}`}>
            <h6 className="App-noticeBannerText">{this.state.notice}</h6>
            <button className="App-noticeBannerBtn"
              type="button"
              onClick={this._togglenoticeBannerActive}>
              ❖
            </button>
          </div>

          <div className="App-toggleBar">
            <button className={`App-toggleBarBtn ${!this.state.showVideoContainer ? 'enabled' : 'disabled'}`}
              title={`Video container ${this.state.showVideoContainer ? 'showing' : 'hidden'}`}
              type="button"
              onClick={this._toggleVideoContainer}>
              ❒
            </button>
            <button className={`App-toggleBarBtn ${!this.state.showKeysContainer ? 'enabled' : 'disabled'}`}
              title={`Input keys container ${this.state.showKeysContainer ? 'showing' : 'hidden'}`}
              type="button"
              onClick={this._toggleKeysContainer}>
              ※
            </button>
            <button className={`App-toggleBarBtn ${!this.state.showPadContainer ? 'enabled' : 'disabled'}`}
              title={`Joypad container ${this.state.showPadContainer ? 'showing' : 'hidden'}`}
              type="button"
              onClick={this._togglePadContainer}>
              ⦿
            </button>
            <button className={`App-toggleBarBtn ${!this.state.showSpeakContainer ? 'enabled' : 'disabled'}`}
              title={`Speak container ${this.state.showSpeakContainer ? 'showing' : 'hidden'}`}
              type="button"
              onClick={this._toggleSpeakContainer}>
              {/* // eslint-disable-next-line */}
              ✌︎
            </button>
            <button className={`App-toggleBarBtn ${!this.state.showMessageFeedContainer ? 'enabled' : 'disabled'}`}
              title={`Message container ${this.state.showMessageContainer ? 'showing' : 'hidden'}`}
              type="button"
              onClick={this._toggleMessageFeedContainer}>
              ✉
            </button>
          </div>
        </header>

        <main className="App-section App-section-main">
          <JProvider value={{
            directionX: this.state.directionX,
            directionY: this.state.directionY,
            lastDirectionX: this.state.lastDirectionX,
            lastDirectionY: this.state.lastDirectionY,
            _onKeyPressSpace: this._onKeyPressSpace,
            _onDirectionalInput: this._onDirectionalInput,
            _sendDirection: this._sendDirection
          }}>
            <div className={`App-padContainer ${this.state.showPadContainer ? 'show' : 'hide'}`}>
              <JoystickInput/>
            </div>
          </JProvider>

          <div className={`App-keysContainer ${this.state.showKeysContainer ? 'show' : 'hide'}`}>
            <JProvider value={{
              _onKeyPressUp: this._onKeyPressUp,
              _onKeyPressDown: this._onKeyPressDown,
              _onKeyPressLeft: this._onKeyPressLeft,
              _onKeyPressRight: this._onKeyPressRight,
              _onKeyPressSpace: this._onKeyPressSpace,
              _sendDirection: this._sendDirection
            }}>
              <KeyboardInput/>
            </JProvider>
            <JProvider value={{
              armX: this.state.armX,
              armY: this.state.armY,
              clawDirection: this.state.clawDirection,
              lastArmX: this.state.lastArmX,
              lastArmY: this.state.lastArmY,
              lastClawDirection: this.state.lastClawDirection,
              _onSliderChangeArmX: this._onSliderChangeArmX,
              _onSliderChangeArmY: this._onSliderChangeArmY,
              _onSliderChangeClawDirection: this._onSliderChangeClawDirection,
              _sendDirection: this._sendDirection
            }}>
              <SliderInput/>
            </JProvider>
          </div>
        </main>

        <section className={`App-section App-section-speakForm ${this.state.showSpeakContainer ? 'show' : 'hide'}`}>
          <JProvider value={{
            speak: this.state.speak,
            _onSpeakChange: this._onSpeakChange,
            _onSpeakSubmit: this._onSpeakSubmit
          }}>
            <SpeakInput/>
          </JProvider>
        </section>

        {/* <section>
          <pre className="App-log"></pre>
        </section> */}

        <section className={`App-section App-section-messageFeed ${this.state.showMessageFeedContainer ? 'show' : 'hide'}`}>
          <h3>Message feed</h3>
          <div className="App-feedContainer">
            <button className="App-btn"
                    type="button"
                    onClick={this._onFeedEmpty}>
                    Empty message feed
            </button>
            <ul className="App-feed">
              {/* this.state.message */}
              {this.state.logFeed.map( (name, index) => <li key={index}>{name}</li> )}
            </ul>
          </div>
        </section>

        <section className="App-section App-activeComponents hide">
          <LeapMotionTracker socket={this.websocket} disabled={this.state.leapMotionDisabled} />
          <USBController socket={this.websocket} disabled={this.state.usbControllerDisabled} />
        </section>
      </div>
    );
  }
}

export default App;
