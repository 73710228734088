import React, { Component } from 'react';
import { JConsumer } from "../../JContext";

class SpeakInput extends Component {
    render() {
        return (
            <JConsumer>
                { (context) =>
                    <form className="App-speakForm"
                        method="post">
                        <div className="App-textareaContainer">
                            <textarea className="App-textarea App-jbot-speak-textarea"
                                placeholder="Text to say"
                                name="jbot-speak"
                                value={context.speak}
                                onChange={(e) => { context._onSpeakChange(e)} }>
                            </textarea>
                        </div>
                        <button className="App-btn"
                            type="submit"
                            onClick={context._onSpeakSubmit}>
                            Speak it!
                        </button>
                    </form>
                }
            </JConsumer>
        )
    }
}

export default SpeakInput