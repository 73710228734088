import React, { Component } from "react"
import Leap from 'leapjs'

const SENSITIVITY = 0.2

class LeapMotionTracker extends Component {
    constructor(props) {
        super(props)

        this.initialDistanceFromLeap = null
        this.initialHandTwist = null
        this.lastSent = null
        this.lastSentGrabStrength = 0
    }

    shouldComponentUpdate() {
        return false
    }

    componentDidMount() {
        this._leapLoop()
    }

    _sendToSocket = (obj) => {
        if (Math.abs(obj.grabStrength - this.lastSentGrabStrength) > SENSITIVITY || 
            (obj.grabStrength === 1 && this.lastSentGrabStrength !== 1 ) || 
            (obj.grabStrength === -1 && this.lastSentGrabStrength !== -1 )) {

            console.log({ arm: [-1, 0, obj.grabStrength] })

            if(this.props.socket){
                this.props.socket.send(JSON.stringify({ arm: [-1, 0, obj.grabStrength]}))
            }

            this.initialHandTwist = null

            this.lastSentGrabStrength = obj.grabStrength
        }
    }

    _leapLoop = () => {

        if(this.props.disabled){
            return false
        }

        console.log('INIT LEAP')

        Leap.loop(frame => {
            if (frame.hands.length > 0) {
                const hand = frame.hands[0]

                if (hand.type === 'right' && true) {
                    const distanceFromLeap = hand.stabilizedPalmPosition[1]
                    const grabStrength = hand.grabStrength
                    const handTwist = hand.direction[0]

                    if(!this.initialDistanceFromLeap){
                        this.initialDistanceFromLeap = distanceFromLeap
                    }

                    if (!this.initialHandTwist) {
                        this.initialHandTwist = handTwist
                    }

                    this._calculateData({
                        grabStrength: grabStrength,
                        distanceFromLeap: distanceFromLeap,
                        handTwist: handTwist
                    })
                }
            }

            if (frame.hands.length === 0){
                this.initialDistanceFromLeap = null
            }
        }, null)
    }

    _calculateData = ({ grabStrength, distanceFromLeap, handTwist })=>{

        // const maximumDistance = this.initialDistanceFromLeap + 150
        // const minimumDistance = this.initialDistanceFromLeap - 150

        const calculatedHandTwist = ((handTwist - -0.5) * (1 - -1)) / (0.5 - -0.5) + -1
        const calculatedGrabStrength = this._remap(grabStrength)*-1

        this._sendToSocket({ grabStrength: calculatedGrabStrength, handTwist: calculatedHandTwist })

        // console.log(grabStrength, distanceFromLeap)
    }

    _remap = (value)=>{
        return ((value - 0) * (1 - -1)) / (1 - 0) + -1
    }

    render() {
        return (
            <div>
                <p>LEAP MOTION TRACKER COMPONENT INCLUDED</p>
            </div>
        )
    }
}

export default LeapMotionTracker